import * as constants from './constants'
import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import Cookies from 'js-cookie'
import { TOKEN_KEY } from '@/constants'

const defaultState = fromJS({
  menuCollapsed: false,
  isLogin: true,
  loginUser: {},
  breadcrumb: [], // 二级页面导航面包屑
  villageList: {}
})

const reducer = (state = defaultState, action) => {
  const { type, value } = action
  switch (type) {
    case constants.MENU_COLLAPSED:
      return state.set('menuCollapsed', !state.get('menuCollapsed'))
    case constants.CHANGE_LOGIN_STATUS:
      if (!value) {
        Cookies.remove(TOKEN_KEY)
      }
      return state.set('isLogin', value)
    case constants.SET_BREADCRUMB:
      return state.set('breadcrumb', value)
    case constants.SET_VILLAGE_LIST:
      const villageList = {}
      value.forEach(item => villageList[item.id] = item)
      return state.set('villageList', villageList)
    case constants.SET_LOGIN_USER:
      return state.set('loginUser', value)
    default:
      return state
  }
}

export default combineReducers({
  common: reducer
})
