import React, { useEffect, Suspense, Fragment } from 'react'
import { Route, withRouter } from 'react-router-dom'
import { Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'
import { connect } from 'react-redux'
import Login from '@/pages/login'
import AppMenu from 'components/menu'
import AppHeader from 'components/header'
import AppRouter from '../../routes'
import { getVillageList, setLoginStatus, getUserInfo } from 'store/actionCreators'
import './style.scss'

function Container(props) {
  const { isLogin, loginUser, getVillageList, getUserInfo } = props
  useEffect(() => {
    if (isLogin) {
      getVillageList()
      getUserInfo()
    } else {
      props.history.replace('/login')
    }
  }, [isLogin])

  return (
    <div className="container">
      <Route path="/login" component={Login}/>
      {isLogin && !!loginUser && !!loginUser.id && (
        <Fragment>
          <AppMenu/>
          <div className="content">
            <AppHeader/>
            <div className="page">
              <div className={props.location.pathname === '/' ? 'page--content page--content-home' : 'page--content'}>
                <Suspense fallback={<Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin/>}/>}>
                  <AppRouter/>
                </Suspense>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default connect(state => ({
  isLogin: state.getIn(['common', 'isLogin']),
  loginUser: state.getIn(['common', 'loginUser'])
}), dispatch => ({
  getVillageList: () => dispatch(getVillageList()),
  setLoginStatus: value => dispatch(setLoginStatus(value)),
  getUserInfo: () => dispatch(getUserInfo())

}))(withRouter(Container))
