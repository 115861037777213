export const mobileValidator = (rule, value) => {
  if (!value || !value.trim()) {
    return Promise.reject(new Error('手机号不能为空'))
  }
  if (!/^1\d{10}$/.test(value)) {
    return Promise.reject(new Error('请输入合法的手机号'))
  }

  return Promise.resolve()
}
