import React, { useRef } from 'react'
import { Form, Input, message, Modal } from 'antd'
import { changePassword } from 'request/admin'

function EditPassword(props) {
  const { visible, close } = props
  const formRef = useRef()

  const onSave = async() => {
    const { password, newPassword, newPassword1 } = formRef.current.getFieldsValue()
    if (newPassword !== newPassword1) {
      return message.warn('确认新密码不一致')
    }
    const { success, message: errMsg } = await changePassword({ password, newPassword })
    if (!success) {
      return message.error(errMsg)
    }
    message.success('修改成功')
    close()
  }

  return (
    <Modal
      visible={visible}
      title="修改密码"
      okText="确定"
      cancelText="取消"
      width="500px"
      maskClosable={false}
      onCancel={close}
      onOk={() => formRef.current.submit()}
    >
      <Form
        labelCol={{ span: 6 }}
        wrapperCol={{ span: 18 }}
        ref={formRef}
        onFinish={onSave}
      >
        <Form.Item
          label="旧密码"
          name="password"
          rules={[
            { required: true, message: '请输入旧密码' },
            { max: 16, message: '密码不超过16个字符' }
          ]}
        >
          <Input type="password" placeholder="请输入旧密码"/>
        </Form.Item>
        <Form.Item
          label="新密码"
          name="newPassword"
          rules={[
            { required: true, message: '请输入新密码' },
            { max: 16, message: '密码不超过16个字符' }
          ]}
        >
          <Input type="password" placeholder="请输入新密码"/>
        </Form.Item>
        <Form.Item
          label="确认新密码"
          name="newPassword1"
          rules={[
            { required: true, message: '请输入确认新密码' },
            { max: 16, message: '密码不超过16个字符' }
          ]}
        >
          <Input type="password" placeholder="请输入确认新密码"/>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export default EditPassword
