import { lazy } from 'react'

const routes = [
  {
    path: '/',
    component: lazy(() => import('../pages/home'))
  },
  {
    path: '/user',
    component: lazy(() => import('../pages/user'))
  },
  {
    path: '/user/print',
    component: lazy(() => import('../pages/user/Print'))
  },
  {
    path: '/meter-reader',
    component: lazy(() => import('../pages/meter-reader'))
  },
  {
    path: '/payment',
    component: lazy(() => import('../pages/payment'))
  },
  {
    path: '/message/list',
    component: lazy(() => import('../pages/message/index'))
  },
  {
    path: '/system/user',
    component: lazy(() => import('../pages/system/user'))
  },
  {
    path: '/system/user/group',
    component: lazy(() => import('../pages/system/user-group'))
  },
  {
    path: '/system/announcement',
    component: lazy(() => import('../pages/system/announcement'))
  }
]

export default routes
