import React, { useState, useEffect, Fragment } from 'react'
import { Menu } from 'antd'
import { withRouter } from 'react-router-dom'
import { connect } from 'react-redux'
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import MENU from './constants'
import { actionCreators } from 'store'
import logo from '@/assets/images/logo.svg'
import './style.scss'

function AppMenu(props) {
  const { menuCollapsed, setBreadcrumb, loginUser, setMenuCollapsed } = props
  const [defaultSelected, setDefaultSelected] = useState([])
  const [defaultOpen, setDefaultOpen] = useState([])
  const [openKeys, setOpenKeys] = useState([])
  const [menuList, setMenuList] = useState([])

  useEffect(() => {
    setMenuList(MENU)
  }, [loginUser])

  useEffect(() => {
    const { pathname } = props.location
    // 根据url获取当前应该展开和选中的菜单
    const getActiveKey = (menuList, path, parentKey) => {
      for (const menu of menuList) {
        if (menu.path === path) {
          return { key: menu.key, label: menu.label, parentKey }
        }

        if (menu.children) {
          const selected = getActiveKey(menu.children, path, menu.key)
          if (selected) {
            return selected
          }
        }
      }
      return false
    }
    // 获取选中和展开的菜单
    const selectedMenu = getActiveKey(MENU, pathname, '/')
    let key = '' // 默认选中菜单项(一级或二级菜单)
    let openKey = '' // 默认展开菜单(一级菜单)
    if (selectedMenu) {
      key = selectedMenu.key
      openKey = selectedMenu.parentKey
      setBreadcrumb(selectedMenu.label)
    }
    setDefaultOpen([openKey])
    setDefaultSelected([key])
    if (openKeys.indexOf(openKey) === -1) {
      setOpenKeys([openKey])
    }
  }, [props.location.pathname])

  const onMenuClick = menu => {
    const routePath = menu.item.props.path
    props.history.push(routePath)
  }

  const onMenuChange = keys => {
    const lastOpenKey = keys.find(key => openKeys.indexOf(key) === -1)
    if (!MENU.find(menu => menu.key === lastOpenKey)) {
      setOpenKeys(keys)
    } else {
      setOpenKeys(lastOpenKey ? [lastOpenKey] : [])
    }
  }

  return (
    <div className={menuCollapsed ? 'app-menu' : 'app-menu app-menu--collapsed'}>
      {defaultSelected.length > 0 && defaultOpen.length > 0 && (
        <Fragment>
          <header className={menuCollapsed ? 'header header-collapsed' : 'header'}>
            <div className="header-logo">
              <img src={logo} alt=""/>
            </div>
            {!menuCollapsed && (
              <div className="header-text">东吴水厂</div>
            )}
          </header>
          <Menu
            defaultSelectedKeys={defaultSelected}
            defaultOpenKeys={defaultOpen}
            selectedKeys={defaultSelected}
            openKeys={openKeys}
            mode="inline"
            theme="dark"
            inlineCollapsed={menuCollapsed}
            onClick={onMenuClick}
            onOpenChange={onMenuChange}
            style={{
              background: '#343956',
              color: '#bcbcc6',
              height: 'calc(100% - 50px)',
              paddingTop: '10px'
            }}
          >
            {menuList.map(menu => {
              return menu.children
                ? (
                  (!menu.auth || (menu.auth.indexOf(loginUser.groupId) > -1)) ? (
                    <Menu.SubMenu
                      key={menu.key}
                      title={
                        <span>
                        {menu.icon}
                          <span>{menu.label}</span>
                      </span>
                      }
                    >
                      {menu.children.map(sub => {
                        return (
                          <Menu.Item key={sub.key} path={sub.path}>
                            {sub.label}
                          </Menu.Item>
                        )
                      })}
                    </Menu.SubMenu>
                  ) : ''
                ) : (
                  (!menu.auth || (menu.auth.indexOf(loginUser.groupId) > -1)) ? (
                    <Menu.Item key={menu.key} path={menu.path}>
                      {menu.icon}
                      <span>{menu.label}</span>
                    </Menu.Item>
                  ) : ''
                )
            })}
              </Menu>
              <div className="collapse" onClick={setMenuCollapsed}>
            {menuCollapsed ? (<MenuUnfoldOutlined/>) : (<MenuFoldOutlined/>)}
              </div>
              </Fragment>
              )}
          </div>
          )
          }

      export default connect(state => ({
      menuCollapsed: state.getIn(['common', 'menuCollapsed']),
      loginUser: state.getIn(['common', 'loginUser'])
    }), dispatch => ({
      setBreadcrumb(value) {
      dispatch(actionCreators.setBreadcrumb(value))
    },
      setMenuCollapsed() {
      dispatch(actionCreators.setMenuCollapsed())
    }
    }))(withRouter(AppMenu))
