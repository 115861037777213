import React from 'react'
import { HashRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import store from 'store/index'
import Container from 'components/container'
import './App.scss'

function App() {
  return (
    <div className="App">
      <Provider store={store}>
        <HashRouter>
          <Container/>
        </HashRouter>
      </Provider>
    </div>
  )
}

export default App
