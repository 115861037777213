import axios from 'axios'
import store, { actionCreators } from '../store'

// 部署线上要加/api
axios.defaults.baseURL = '/api'
axios.defaults.withCredentials = true

export const CAPTCHA_URL = '/api/account/captcha'

axios.interceptors.request.use(config => {
  config.headers = Object.assign(config.headers, {
    'X-Requested-With': 'XMLHttpRequest',
  })
  return config
})

axios.interceptors.response.use(response => {
  const data = response.data
  if (data.code && Number(data.code) === 401) {
    store.dispatch(actionCreators.setLoginStatus(false))
    setTimeout(() => {
      window.location.href = '/#login'
    })
  }
  return data
})

export const get = (url, params, options) => {
  return axios.get(url, { params, ...options })
}

export const post = (url, data, options) => {
  return axios.post(url, data, options)
}
