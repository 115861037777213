import React, { useState, useRef, Fragment } from 'react'
import { Form, Input, Button, Row, Col, message } from 'antd'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import { adminLogin } from 'request/account'
import { setLoginStatus } from 'store/actionCreators'
import { CAPTCHA_URL } from 'request'
import './style.scss'

function Login(props) {
  const { setLoginStatus, isLogin } = props
  const [captchaUrl, setCaptchaUrl] = useState(`${CAPTCHA_URL}?t=${new Date().getTime()}`)
  const formRef = useRef()

  const changeCaptcha = () => {
    setCaptchaUrl(`${CAPTCHA_URL}?t=${new Date().getTime()}`)
  }

  const onFinish = async() => {
    const values = formRef.current.getFieldsValue()
    const { success, message: errMsg } = await adminLogin({ ...values })
    if (!success) {
      changeCaptcha()
      return message.error(errMsg)
    }
    setLoginStatus(true)
  }

  return (
    <div className="login">
      {isLogin ? (
        <Redirect to="/"/>
      ) : (
        <Fragment>
          <div className="login-form">
            <div className="title">东吴镇自来水管理系统</div>
            <Form
              labelCol={{ span: 5 }}
              wrapperCol={{ span: 19 }}
              initialValues={{ remember: true }}
              onFinish={onFinish}
              colon={false}
              requiredMark={false}
              ref={formRef}
            >
              <Form.Item
                label="手机号"
                name="mobile"
                rules={[{ required: true, message: '请输入手机号' }]}
              >
                <Input placeholder="请输入手机号"/>
              </Form.Item>

              <Form.Item
                label="密码"
                name="password"
                rules={[{ required: true, message: '请输入密码' }]}
              >
                <Input.Password placeholder="请输入密码"/>
              </Form.Item>
              <Form.Item
                label="验证码"
              >
                <Row gutter={8}>
                  <Col span={14}>
                    <Form.Item
                      name="captcha"
                      noStyle
                      rules={[{ required: true, message: '请输入验证码' }]}
                    >
                      <Input placeholder="请输入验证码"/>
                    </Form.Item>
                  </Col>
                  <Col span={10}>
                    <img className="captcha" onClick={changeCaptcha} src={captchaUrl}/>
                  </Col>
                </Row>
              </Form.Item>
              <Form.Item label=" ">
                <Button type="primary" style={{ width: '100%' }} onClick={() => formRef.current.submit()}>登录</Button>
              </Form.Item>
            </Form>
          </div>
          <div className="footer">
            宁波市鄞州区东吴镇，<a href="http://beian.miit.gov.cn/" target="_blank">浙ICP备18021835号</a>
          </div>
        </Fragment>
      )}
    </div>
  )
}

export default connect(state => ({
  isLogin: state.getIn(['common', 'isLogin'])
}), dispatch => ({
  setLoginStatus: value => dispatch(setLoginStatus(value))
}))(Login)
