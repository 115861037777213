import React, { Fragment } from 'react'
import { Route } from 'react-router-dom'
import routes from './routes'

function AppRouter() {
  return (
    <Fragment>
      {
        routes.map((route, index) => {
          return <Route path={route.path} key={index} exact component={route.component}/>
        })
      }
    </Fragment>
  )
}

export default AppRouter
