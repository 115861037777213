import React, { useState } from 'react'
import { DownOutlined } from '@ant-design/icons'
import { Menu, Dropdown } from 'antd'
import { connect } from 'react-redux'
import { adminLogout } from 'request/account'
import { actionCreators } from 'store'
import './EditPassword'
import './style.scss'
import EditPassword from 'components/header/EditPassword'

function Header(props) {
  const { setLoginStatus, loginUser, breadcrumb } = props
  const [isShowEditPassword, setIsShowEditPassword] = useState(false)

  const logout = async() => {
    await adminLogout()
    setLoginStatus(false)
  }

  const menu = (
    <Menu>
      <Menu.Item>
        <span onClick={() => setIsShowEditPassword(true)}>
          修改密码
        </span>
      </Menu.Item>
      <Menu.Item>
        <span onClick={logout}>
          退出系统
        </span>
      </Menu.Item>
    </Menu>
  )

  return (
    <div className="app-header">
      <div className="page-title">
        {breadcrumb}
      </div>
      <div className="account">
        <Dropdown overlay={menu}>
          <div className="name">{loginUser.name} <DownOutlined/></div>
        </Dropdown>
      </div>
      {isShowEditPassword && (
        <EditPassword
          visible={isShowEditPassword}
          close={() => setIsShowEditPassword(false)}
        />
      )}
    </div>
  )
}

export default connect(state => ({
  menuCollapsed: state.getIn(['common', 'menuCollapsed']),
  loginUser: state.getIn(['common', 'loginUser']),
  breadcrumb: state.getIn(['common', 'breadcrumb'])
}), dispatch => ({
  setMenuCollapsed() {
    dispatch(actionCreators.setMenuCollapsed())
  },
  setLoginStatus(value) {
    dispatch(actionCreators.setLoginStatus(value))
  }
}))(Header)
