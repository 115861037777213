export * from './form-validator'

export const TOKEN_KEY = 'gov-backend-web-token'
export const ACCOUNT_NAME_KEY = 'gov-backend-web-account'

export const ANNOUNCEMENT_USER_TYPE_MAP = {
  1: '用户',
  2: '抄表员',
  3: '系统管理员'
}

export const ADMIN_TYPE_MAP = {
  1: '超级管理员',
  2: '管理员'
}

export const FORM_LAYOUT = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 }
}
