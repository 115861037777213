import * as constants from './constants'
import { get } from 'request'
import { message } from 'antd'
import { getLoginUser } from 'request/account'

export const setMenuCollapsed = () => ({ type: constants.MENU_COLLAPSED })
export const setLoginStatus = value => ({ type: constants.CHANGE_LOGIN_STATUS, value })
export const setBreadcrumb = value => ({ type: constants.SET_BREADCRUMB, value })

export const getUserInfo = () => {
  return dispatch => {
    getLoginUser().then(res => {
      if (res.success) {
        dispatch({ type: constants.SET_LOGIN_USER, value: res.data })
      }
    })
  }
}

export const getVillageList = () => {
  return dispatch => {
    get('/common/villageList').then(res => {
      if (res.success) {
        dispatch({ type: constants.SET_VILLAGE_LIST, value: res.data })
      } else {
        message.error('获取事件类型失败')
      }
    })
  }
}
