import React from 'react'
import { SettingOutlined, FunnelPlotOutlined, HomeOutlined, SolutionOutlined, MessageOutlined } from '@ant-design/icons'

const menuList = [
  {
    key: 'home',
    label: '首页',
    icon: <HomeOutlined />,
    path: '/'
  },
  {
    key: 'consumption',
    label: '用户用量',
    icon: <FunnelPlotOutlined />,
    children: [
      {
        key: 'user-list',
        label: '用户列表',
        path: '/user'
      },
      {
        key: 'payment',
        label: '缴费列表',
        path: '/payment'
      }
    ]
  },
  {
    key: 'meter-reader',
    label: '抄表员',
    icon: <SolutionOutlined />,
    children: [
      {
        key: 'meter-reader-list',
        label: '抄表员列表',
        path: '/meter-reader'
      }
    ]
  },
  {
    key: 'message',
    label: '短信',
    icon: <MessageOutlined />,
    children: [
      {
        key: 'message-list',
        label: '发送记录',
        path: '/message/list'
      }
    ]
  },
  {
    key: 'system',
    label: '系统管理',
    icon: <SettingOutlined />,
    auth: [1],
    children: [
      {
        key: 'system-user-list',
        label: '管理员',
        path: '/system/user'
      },
      // {
      //   key: 'system-user-group',
      //   label: '管理组',
      //   path: '/system/user/group'
      // },
      {
        key: 'system-announcement',
        label: '系统公告',
        path: '/system/announcement'
      }
    ]
  }
]

export default menuList
